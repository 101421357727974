import React from "react";
import styles from "./FooterInput.module.scss";
import { ReactComponent as Search } from "../../../assets/search.svg";
import { ReactComponent as SearchBlack } from "../../../assets/searchblack.svg";
import { useTranslation } from "react-i18next";

const FooterInput = ({ showSearchPDVModalHandler }) => {
  const { t, i18n } = useTranslation();
  const isCamaronPatch = window.location.host.includes("camaron");
  return (
    <div className={styles.footerInput} onClick={showSearchPDVModalHandler} style={{ border: isCamaronPatch ? "1px solid #979797" : "" }}>
      {t("Buscar por punto de venta")}
      <figure>{isCamaronPatch ? <SearchBlack /> : <Search />}</figure>
    </div>
  );
};

export default FooterInput;
