import React, { useEffect } from "react";
import styles from "./Map.module.scss";
import GoogleMapReact from "google-map-react";
import { connect } from "react-redux";
import { sendProducts, setPDV, getPlace, getPlaceFromSearchPDV } from "../../store/generalActions";
import { ReactComponent as InitialPDVMarker } from "../../assets/pdv-initial.svg";

const options = {
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false
};

const Map = ({ sellPoints, userLocation, selectedSellPoint, sendProducts, isSearching, setPDV, getPlace, getPlaceFromSearchPDV }) => {
  useEffect(() => {
    //Cambiar el metodo por el nuevo GET de la api
    sendProducts(userLocation, [1], true);
  }, []);

  return (
    <div className={styles.map}>
      <GoogleMapReact bootstrapURLKeys={{ key: "AIzaSyC7tLWUWOfUmyzucUbMa73aXtuY9sqhwJ0" }} yesIWantToUseGoogleMapApiInternals defaultCenter={userLocation} defaultZoom={17} options={options}>
        <div style={{ width: "15px", height: "15px", background: "#511958", borderRadius: "100%" }} lat={userLocation.lat} lng={userLocation.lng}></div>
        {sellPoints &&
          sellPoints.map((sellPoint, key) => {
            return (
              <figure
                key={key}
                className={`${styles.sellPointMarker} ${isSearching ? styles[sellPoint.status] : ""}`}
                lat={sellPoint.latitude}
                lng={sellPoint.longitude}
                onClick={() => {
                  if (isSearching) getPlace(sellPoint.google_maps_id, sellPoint);
                  else getPlaceFromSearchPDV(sellPoint.google_maps_id);
                  // if (isSearching) setPDV(sellPoint);
                }}></figure>
            );
          })}
      </GoogleMapReact>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    sellPoints: state.sellPoints,
    userLocation: state.userLocation,
    isSearching: state.isSearching
  };
};

export default connect(mapStateToProps, { sendProducts, setPDV, getPlace, getPlaceFromSearchPDV })(Map);
