import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translations: {
      "home-alert": "Pick a selling shop on the map to let everyone know a product is in stock!",
      "productos-title": "Find where products are available",
      "Buscar productos disponibles": "Search for items in stock",
      "reportar-disponibilidad": "To report a product is in stock, pick a shop on the map!",
      "Hecho con":"Created with",
      "por":"by",
      "Buscar por punto de venta": "Search by shop",
      "Cómo funciona?": "How does it work?",
      "Buscar productos":"Search for items you need",
      "Reportar disponibilidad":"Update availability",
      "¡Ayudanos a ayudar!":"Help us so we can help",
      "Haciendo click en “buscar productos disponibles podés completar un formulario con los productos que buscás. Nosotros hacemos el resto y te mostramos en el mapa qué puntos de venta tienen stock.": "By clicking on 'Search for items in stock' you check the boxes with the items you need. We do the rest and show you the nearby shops that have the items in stock on a map.",
      "Seleccionando en el mapa un punto de venta podés informar qué productos tiene ese local disponibles para que los demás puedan saberlo.": "By picking a shop on the map, you can report which products are in stock so everyone else can know.",
      "Al ser una plataforma colaborativa, cuántos más datos carguen las personas en tiempo real, ¡más confiable y precisa va a ser! Ayudanos contando cuáles productos encontraste.": "This collaborative platform will get more reliable and precise as users (you) keep entering data in real time. Help us know which items you found!",
      "Colaboran":"Part of this project",
      "Compartir":"Share",
      "¿Qué productos buscas?":"What items are you looking for?",
      "Hacé scroll para ver más productos": "Scroll down to see more products",
      Buscar: "Search",
      "Hacé scroll para ver más productos":"Scroll to see more products",
      "Resultados": "Results",
      "Seleccioná en el mapa el punto de venta para ver qué productos tiene y saber cómo ir.": "Choose a shop on the map to see which items are in stock and learn how to get there.",
      "Tiene todos los productos": "All items as are in stock",
      "Tiene alguno de los productos": "Some items are in stock",
      "No tiene niguno de los productos": "All items are out of stock",
      "Alcohol etílico": "Alcohol",
      "Carnes":"Meat",
      "Fideos": "Noodles",
      "Alcohol en gel": "Hand sanitizer",
      "Toallas antibacteriales": "Antibacterial towels",
      Jabón: "Soap",
      Barbijo: "Chin strap",
      "Papel higiénico": "Toilet paper",
      "Toallas de papel": "Paper towels",
      Medicamentos: "Medicine",
      Termómetro: "Thermometer",
      Agua: "Water",
      Lavandina: "Bleach",
      "Guantes de goma": "Rubber gloves",
      "Harina": "Flour",
      "Huevo":"Egg",
      "Pan":"Bread",
      "Paracetamol":"Paracetamol",
      "Lata de atún":"Can of tuna",
      "Lata de choclo":"Can of corn",
      "Lata de tomate":"Can of tomatoes",
      "horarios":"Busiest hours",
      "Actualizar disponibilidad": "Update availability",
      "Ir con Google Maps": "Go with Google Maps",
      "Actualizado": "Updated",
      "Sin información disponible":"No information available",
      "Hay": "Available",
      "No hay": "No Available",
      "Sin datos": "No data",
      "¿Qué productos encontraste?": "Which items did you find?",
      "Reportar": "Report",
      "¡Gracias por colaborar!": "Thanks for the update!",
      "Tu ayuda es fundamental para que los demás puedan encontrar productos.": "Your help is vital so everyone else can find what they need",
      "Finalizar": "End"
    }
  },
  es: {
    translations: {
      "home-alert": "Para avisar sobre la disponibilidad de un producto seleccioná el punto de venta en el mapa!",
      "productos-title": "Encontrá dónde hay productos disponibles",
      "Buscar productos disponibles": "Buscar productos disponibles",
      "reportar-disponibilidad": "Para reportar la disponibilidad de un producto, ¡seleccioná el punto de venta en el mapa!",
      "Hecho con":"Hecho con",
      "por":"por",
      "Buscar por punto de venta": "Buscar por punto de venta",
      "Cómo funciona?": "Cómo funciona?",
      "Buscar productos":"Buscar productos",
      "Reportar disponibilidad":"Reportar disponibilidad",
      "¡Ayudanos a ayudar!":"¡Ayudanos a ayudar!",
      "Haciendo click en “buscar productos disponibles podés completar un formulario con los productos que buscás. Nosotros hacemos el resto y te mostramos en el mapa qué puntos de venta tienen stock.": "Haciendo click en “buscar productos disponibles podés completar un formulario con los productos que buscás. Nosotros hacemos el resto y te mostramos en el mapa qué puntos de venta tienen stock.",
      "Seleccionando en el mapa un punto de venta podés informar qué productos tiene ese local disponibles para que los demás puedan saberlo.": "Seleccionando en el mapa un punto de venta podés informar qué productos tiene ese local disponibles para que los demás puedan saberlo",
      "Al ser una plataforma colaborativa, cuántos más datos carguen las personas en tiempo real, ¡más confiable y precisa va a ser! Ayudanos contando cuáles productos encontraste.": "Al ser una plataforma colaborativa, cuántos más datos carguen las personas en tiempo real, ¡más confiable y precisa va a ser! Ayudanos contando cuáles productos encontraste.",
      "Colaboran":"Colaboran",
      "Compartir":"Compartir",
      "¿Qué productos buscas?":"¿Qué productos buscas?",
      "Hacé scroll para ver más productos": "Hacé scroll para ver más productos",
      Buscar: "Buscar",
      "Resultados": "Resultados",
      "Seleccioná en el mapa el punto de venta para ver qué productos tiene y saber cómo ir.": "Seleccioná en el mapa el punto de venta para ver qué productos tiene y saber cómo ir.",
      "Tiene todos los productos": "Tiene todos los productos",
      "Tiene alguno de los productos": "Tiene alguno de los productos",
      "No tiene niguno de los productos": "No tiene niguno de los productos",
      "Alcohol etílico": "Alcohol etílico",
      "Carnes":"Carnes",
      "Fideos": "Fideos",
      "Alcohol en gel": "Alcohol en gel",
      Jabón: "Jabón",
      Barbijo: "Barbijo",
      "Papel higiénico": "Papel higiénico",
      "Toallas de papel": "Toallas de papel",
      Medicamentos: "Medicamentos",
      Termómetro: "Termómetro",
      Agua: "Agua",
      Lavandina: "Lavandina",
      "Guantes de goma": "Guantes de goma",
      "Harina": "Harina",
      "Huevo":"Huevo",
      "Pan":"Pan",
      "Paracetamol":"Paracetamol",
      "Toallas antibacteriales":"Toallas antibacteriales",
      "Lata de atún":"Lata de atún",
      "Lata de choclo":"Lata de choclo",
      "Lata de tomate":"Lata de tomate",
      "horarios":"Horarios picos",
      "Actualizar disponibilidad": "Actualizar disponibilidad",
      "Ir con Google Maps": "Ir con Google Maps",
      "Actualizado": "Actualizado",
      "Sin información disponible":"Sin información disponible",
      "Hay": "Hay",
      "No hay": "No hay",
      "Sin datos": "Sin datos",
      "¿Qué productos encontraste?": "¿Qué productos encontraste?",
      "Reportar": "Reportar",
      "¡Gracias por colaborar!": "¡Gracias por colaborar!",
      "Tu ayuda es fundamental para que los demás puedan encontrar productos.": "Tu ayuda es fundamental para que los demás puedan encontrar productos.",
      "Finalizar": "Finalizar"
    }
  },
  pt: {
    translations: {
      "home-alert": "Para informar sobre a disponibilidade de um produto em estoque, escolha uma loja no mapa!",
      "productos-title": "Procure onde os produtos estão disponíveis",
      "¿Qué productos buscas?": "Buscar por produtos disponíveis",
      "reportar-disponibilidad": "Para informar que um produto está em estoque, escolha uma loja no mapa!",
      "Hecho con":"Criado por",
      "por":"por",
      "Buscar por punto de venta": "Buscar por pontos de venda",
      "Cómo funciona?": "Como funciona?",
      "Buscar productos":"Buscar produtos",
      "Reportar disponibilidad":"Atualização disponível",
      "¡Ayudanos a ayudar!":"Nos ajude a ajudar",
      "Haciendo click en “buscar productos disponibles podés completar un formulario con los productos que buscás. Nosotros hacemos el resto y te mostramos en el mapa qué puntos de venta tienen stock.": "Clicando em 'encontrar produtos disponíveis' você pode preencher um formulário com os produtos que você está procurando. Nós fazemos o resto e mostramos no mapa quais pontos de venda têm estoque.",
      "Seleccionando en el mapa un punto de venta podés informar qué productos tiene ese local disponibles para que los demás puedan saberlo.": "Ao escolher uma loja no mapa, você poderá informar quais produtos estão em estoque para que todos possam saber.",
      "Al ser una plataforma colaborativa, cuántos más datos carguen las personas en tiempo real, ¡más confiable y precisa va a ser! Ayudanos contando cuáles productos encontraste.": "Seja solidário, informe os itens que encontrar, a sua ajuda torna a plataforma mais confiável. Ajude-nos a saber quais itens você encontrou!",
      "Colaboran":"Colaborar",
      "Compartir":"Compartilhar",
      "¿Qué productos buscas?":"Quais itens você está procurando?",
      "Hacé scroll para ver más productos": "Hacé scroll para ver más productos",
      Buscar: "Buscar",
      "Hacé scroll para ver más productos":"Role para ver mais produtos",
      "Resultados": "Resultados",
      "Seleccioná en el mapa el punto de venta para ver qué productos tiene y saber cómo ir.": "Escolha uma loja no mapa para ver quais itens estão em estoque e veja como chegar lá.",
      "Tiene todos los productos": "Todos os itens em estoque",
      "Tiene alguno de los productos": "Alguns itens em estoque",
      "No tiene niguno de los productos": "Itens esgotados",
      "Alcohol en gel": "Álcool em gel",
      "Alcohol etílico": "Álcool",
      Jabón: "Sabão",
      Barbijo: "Chinstrap",
      "Papel higiénico": "Papel higiênico",
      "Toallas de papel": "Toalhas de papel",
      Medicamentos: "Medicamentos",
      Termómetro: "Termômetro",
      Agua: "Agua",
      Lavandina: "lavandina",
      "Guantes de goma": "Luvas de borracha",
      "Harina": "Farinha",
      "Huevo":"Ovo",
      "Pan":"Pan",
      "Paracetamol":"Paracetamol",
      "Toallas antibacteriales":"Toalhas antibacterianas",
      "Lata de atún":"Lata de atum",
      "Lata de choclo":"Lata de milho",
      "Lata de tomate":"Lata de tomate",
      "horarios":"Horários de pico",
      "Actualizar disponibilidad": "Atualização disponível",
      "Ir con Google Maps": "Continue com o Google Maps",
      "Actualizado": "Atualizado",
      "Sin información disponible":"nenhuma informação disponível",
      "Hay": "há",
      "No hay": "não há",
      "Sin datos": "não há dados",
      "¿Qué productos encontraste?": "Quais itens você encontrou?",
      "Reportar": "Relatar",
      "¡Gracias por colaborar!": "Obrigado (a) por ajudar!",
      "Tu ayuda es fundamental para que los demás puedan encontrar productos.": "Sua ajuda é fundamental para que todos possam encontrar o que precisam.",
      "Finalizar": "Finalizar"
    }
  }
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ","
    },

    react: {
      wait: true
    }
  });

export default i18n;
