import React from "react";
import styles from "./SellPointModal.module.scss";
import { connect } from "react-redux";
import { showProviderModalHandler, closePdvHandler } from "../../store/generalActions";
import { ReactComponent as Close } from "../../assets/close.svg";
import { ReactComponent as StatusGreen } from "../../assets/status-green.svg";
import { ReactComponent as StatusGray } from "../../assets/status-gray.svg";
import { ReactComponent as StatusRed } from "../../assets/status-red.svg";
import Chart from "./Chart/Chart";
import { useTranslation } from "react-i18next";

//SI NO NS -> Estados
const PuntoDeVenta = ({ sellPointSelected, showProviderModalHandler, closePdvHandler, isFromSearchPDV }) => {
  const { name, status, sellPointProduct, id, latitude, longitude } = sellPointSelected;
  const { t, i18n } = useTranslation();

  const productItem = (item, key) => {
    let estado;
    let estadoSVG;
    if (item.state.name === "Si") {
      estado = t("Hay");
      estadoSVG = <StatusGreen />;
    } else if (item.state.name === "No") {
      estado = t("No hay");
      estadoSVG = <StatusRed />;
    } else if (item.state.name === "NS") {
      estado = t("Sin datos");
      estadoSVG = <StatusGray />;
    }
    return (
      <div className={styles.productItem} key={key}>
        <span className={styles.productItemName}>{t(item.product.name)}</span>
        <div className={styles.productStatus}>
          <span>{estado}</span>
          {estadoSVG}
          {/* <figure className={styles[item.state.name]}></figure> */}
        </div>
      </div>
    );
  };
  return (
    <>
      <div className={styles.overlay} onClick={closePdvHandler}></div>
      <div className={styles.sellPointModal}>
        <div className={styles.close} onClick={closePdvHandler}>
          <Close color={"#000"} />
        </div>
        <header className={styles.infoContainer}>
          <figure className={`${styles.sellPointMarker} ${styles[status]}`}></figure>
          <div className={styles.sellPointInfo}>
            <h3>{name}</h3>
            <h4>{sellPointSelected.address}</h4>
            <h5>
              {t("Actualizado")} {sellPointSelected.lastUpdated}
            </h5>
          </div>
        </header>
        <div className={styles.productContainer}>{sellPointProduct.map(item => productItem(item))}</div>
        <div className={styles.chart}>
          {!sellPointSelected.popularTime ? (
            <>
              <h3>{t("horarios")}</h3>
              <h4>{t("Sin información disponible")}</h4>
            </>
          ) : (
            <>
              <h3>{t("horarios")}</h3>
              <Chart popularTime={sellPointSelected.popularTime} />
              <div className={styles.hours}>
                <span>06</span>
                <span>09</span>
                <span>12</span>
                <span>15</span>
                <span>18</span>
                <span>21</span>
              </div>
            </>
          )}
        </div>

        <button onClick={showProviderModalHandler} className={styles.button}>
          {t("Actualizar disponibilidad")}
        </button>
        <a href={`https://www.google.com/maps/?q=${sellPointSelected.location.lat},${sellPointSelected.location.lng}`} target='_blank' rel='noopener noreferrer' className={styles.maps} style={{ color: "#000" }}>
          {t("Ir con Google Maps")}
        </a>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    sellPointSelected: state.sellPointSelected,
    isFromSearchPDV: state.isFromSearchPDV
  };
};
export default connect(mapStateToProps, { showProviderModalHandler, closePdvHandler })(PuntoDeVenta);
